import React, { FC, useEffect, useMemo } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { demoCredentialSelector, getDemoCredentials } from '../../state/slices/democredential';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatDateForDisplay } from '../../state';
import { useAuth } from 'react-oidc-context';


export const ShowCompany: FC = () => {
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const demoCredentials = useSelector(demoCredentialSelector);
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(getDemoCredentials({jwtToken: auth.user?.access_token!}));
    }, [auth.user?.access_token]);

// A little bit simplified version
    const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
        arr.reduce((groups, item) => {
            (groups[key(item)] ||= []).push(item);
            return groups;
        }, {} as Record<K, T[]>);


    function getKvkNumber(credential: any) {
        if (credential['kvknummer'] !== undefined) {
            return credential['kvknummer'];
        }
        if (credential['kvkNummer'] !== undefined) {
            return credential['kvkNummer'];
        }
        if (credential['legal_person_identifier'] !== undefined) {
            return credential['legal_person_identifier']?.split('.')[1];
        }
        return undefined;
    }

    const credentials = useMemo(() => groupBy(demoCredentials.list, item => getKvkNumber(JSON.parse(item.credentialJson)))
        , [demoCredentials.list]);

    if (credentials === undefined) {
        return (<></>);
    }

    function getCompanyName(credentials: any[]) {
        return credentials
            .map((credential) => JSON.parse(credential.credentialJson))
            .filter((credential) => credential['legal_person_name'] !== undefined)
            .map((credential) => credential['legal_person_name'])[0];
    }

    function getRole(credentials: any[]) {
        return 'UBO';
    }

    function getLocation(credentials: any[]) {
        return credentials
            .map((credential) => JSON.parse(credential.credentialJson))
            .filter((credential) => credential['locatie'] !== undefined)
            .map((credential) => credential['locatie'])[0];
    }

    function showLpidCredential(id: number, credential: any) {
        return (
            <div  style={{minHeight: '295px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="font-bold">Legal Person Identifier</div>
                    </Grid>
                    <Grid item xs={4}>
                        Legal person name:
                    </Grid>
                    <Grid item xs={8}>
                        {credential.legal_person_name}
                    </Grid>
                    <Grid item xs={4}>
                        Legal person identifier:
                    </Grid>
                    <Grid item xs={8}>
                        {credential.legal_person_identifier}
                    </Grid>
                    <Grid item xs={8}>
                        <Button variant='outlined' onClick={() => issue(id)}>Save in Wallet</Button>
                    </Grid>
                </Grid>
            </div>
        )
    }

    function showKvKRegistrationCredential(id: number, credential: any) {
        return (
            <div  style={{minHeight: '295px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="font-bold">KvK registration</div>
                    </Grid>
                    <Grid item xs={4}>
                        Name:
                    </Grid>
                    <Grid item xs={8}>
                        {credential.naam}
                    </Grid>
                    <Grid item xs={4}>
                        KVK-nummer:
                    </Grid>
                    <Grid item xs={8}>
                        {credential.kvkNummer}
                    </Grid>
                    <Grid item xs={4}>
                        Rechtsvorm:
                    </Grid>
                    <Grid item xs={8}>
                        {credential.rechtsvorm}
                    </Grid>
                    <Grid item xs={4}>
                        Startdatum:
                    </Grid>
                    <Grid item xs={8}>
                        {formatDateForDisplay(credential.startdatum)}
                    </Grid>
                    <Grid item xs={4}>
                        Einddatum:
                    </Grid>
                    <Grid item xs={8}>
                        {formatDateForDisplay(credential.einddatum)}
                    </Grid>
                    <Grid item xs={8}>
                        <Button variant='outlined' onClick={() => issue(id)}>Save in Wallet</Button>
                    </Grid>
                </Grid>
            </div>
        )
    }

    function showUboCredentialCredential(id: number, credential: any) {
        return (
            <div>
                <Grid container spacing={2}>
                    {(credential.ubos as []).map((ubo: any) => {
                        return (
                            <>
                                <Grid item xs={12}>
                                    <div className="font-bold">Ultimate Beneficiary Owner</div>
                                </Grid>
                                <Grid item xs={6}>
                                    Aard economisch belang:
                                </Grid>
                                <Grid item xs={6}>
                                    {ubo.aardEconomischBelang}
                                </Grid>
                                <Grid item xs={6}>
                                    Omvang economisch belang:
                                </Grid>
                                <Grid item xs={6}>
                                    {ubo.omvangEconomischBelang}
                                </Grid>
                                <Grid item xs={6}>
                                    Naam:
                                </Grid>
                                <Grid item xs={6}>
                                    {ubo.persoonsgegevens.voornamen} {ubo.persoonsgegevens.geslachtsnaam}
                                </Grid>
                                <Grid item xs={6}>
                                    Geboortedatum:
                                </Grid>
                                <Grid item xs={6}>
                                    {ubo.persoonsgegevens.geboortedatum}
                                </Grid>
                            </>
                        )
                    })}
                    <Grid item xs={8}>
                        <Button variant='outlined' onClick={() => issue(id)}>Save in Wallet</Button>
                    </Grid>
                </Grid>
            </div>
        )
    }

    function showCredential(credential: any) {
        if (credential.credentialJson === undefined) {
            return undefined;
        }
        let cred = JSON.parse(credential.credentialJson);
        if (credential.templateId && credential.templateId.toLowerCase().includes('lpid')) {
            return showLpidCredential(credential.id, cred);
        }
        if (credential.templateId && credential.templateId.toLowerCase().includes('kvkregistration')) {
            return showKvKRegistrationCredential(credential.id, cred);
        }
        if (credential.templateId && credential.templateId.toLowerCase().includes('ubo')) {
            return showUboCredentialCredential(credential.id, cred);
        }

    }

    function issue(id: number) {
        navigate('/issue/wallet?id=' + id);
    }


    function showKvkNumber(key: string, credentials: any[]) {

        return (
            <Accordion key={key} sx={{boxShadow: 'unset'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: '#aa418c'}}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{paddingTop: '12px', paddingBottom: '12px', paddingLeft: '0px', paddingRight: '0px'}}>
                    <div className="grid w-full">
                        <div className="col-12 md:col-4 font-bold">{getCompanyName(credentials)}</div>
                        <div className="col-12 md:col-2 font-light">{key}</div>
                        <div className="col-12 md:col-3 font-light">{getRole(credentials)}</div>
                        <div className="col-12 md:col-3 font-light">{getLocation(credentials)}</div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="grid" style={{marginLeft: '-1rem', marginRight: '-1rem', marginTop: '-1rem'}}>
                        {credentials.map((credential) => {
                            return (
                                <div className="col-12 md:col-6 p-2" key={credential.id}>
                                    <div className="border-red-100 p-4 shadow-3">
                                        {showCredential(credential)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <div className="mt-4">
            <Typography sx={{fontSize: '2rem', fontFamily: 'M PLUS Rounded 1c', fontWeight: 'bold', color: '#00526e'}}>Mijn KVK</Typography>
            <Typography sx={{fontSize: '1.188rem', fontFamily: 'M PLUS Rounded 1c', fontWeight: 'light', color: '#343434'}}>Je bent ingelogd bij Mijn KVK. Hieronder zie je de organisaties waar je een functie vervult en de gegevens van de organisaties. Mis je organisaties? Er zijn verschillende redenen <a href="https://www.kvk.nl/mijn-kvk/veelgestelde-vragen-ingelogd" style={{textDecoration: 'underline'}}>waarom niet alle organisaties getoond worden</a>.</Typography>
            <Typography sx={{fontSize: '1.5rem', fontFamily: 'M PLUS Rounded 1c', fontWeight: 'bold', color: '#00526e', marginTop: '30px', marginBottom: '20px'}}>Organisaties</Typography>
            <div className="w-full mb-4" style={{paddingRight: '1em'}}>
                <div className="grid w-full" style={{paddingRight: '0.5rem'}}>
                    <div className="col-12 md:col-4 font-bold">Naam</div>
                    <div className="col-12 md:col-2 font-bold">KVK-nummer</div>
                    <div className="col-12 md:col-3 font-bold">Functie</div>
                    <div className="col-12 md:col-3 font-bold">Locatie</div>
                </div>
                {/*<div className="" style={{width: '1em'}}>s</div>*/}
            </div>
            {Object.keys(credentials)
                .filter((key) => key !== undefined && key !== 'undefined') // Add filter to exclude undefined keys
                .map((key) => showKvkNumber(key, credentials[key]))
            }
        </div>
    )
};
