import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';


export const issueCredential = createAsyncThunk(
    'credential/issueCredential', ({jwtToken, id, callbackUrl}: { jwtToken: string | undefined, id?: string, callbackUrl: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/manage/vc/id/' + id;
        return axios.post(url + "?callback=" + callbackUrl, {}, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const startWalletLogin = createAsyncThunk(
    'credential/verifyCredential', ({}: {}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {};

        return axios.post('/public/login', {}, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const checkLoginStatus = createAsyncThunk(
    'credential/getCredential', ({sessionId}: { sessionId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {};

        return axios.get('/public/login/status/' + sessionId, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
