import React, { FC, useEffect } from 'react';
import { useAppDispatch } from '../../state/hooks';
// import { companySelector, getCompany } from '../../state/slices';
import { useSelector } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { credentialSelector, issueCredential } from '../../state/slices/credential';
import { useAuth } from 'react-oidc-context';

export const ScanQRCode: FC = () => {
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    let navigate = useNavigate();
    const credentialState = useSelector(credentialSelector);

    useEffect(() => {
        if (id) {
           dispatch(issueCredential({jwtToken: auth.user?.access_token!, id: id, callbackUrl: ''}));
        }
    }, [auth.user?.access_token, id]);


    function annuleer() {
        navigate('/');
    }

    return (
        <div>
            <Card variant="outlined">
                <CardHeader title='Uitgeven bevoegdheid uittreksel credential'/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Scan onderstaande QR code met je persoonlijke wallet om de geselecteerde bevoegdheid naar je wallet te halen.
                        </Grid>
                        <Grid item xs={12}>
                            { credentialState.singleItem ? <QRCodeCanvas
                                value={credentialState.singleItem!.oidcUrl}
                                size={300}/> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='outlined' onClick={() => annuleer()}>Annuleren</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};
