import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';


export const getDemoCredentials = createAsyncThunk(
        'demoCredential/getDemoCredentials', ({jwtToken}: {jwtToken: string | undefined}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };
            return axios.get('/manage/demoCredential', config)
                .then(response => {
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);

export const getDemoCredential = createAsyncThunk(
        'demoCredential/getDemoCredential', ({jwtToken, id}: {jwtToken: string | undefined, id: string | undefined}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };
            return axios.get('/manage/demoCredential/id/' + id, config)
                .then(response => {
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);

export const saveDemoCredential = createAsyncThunk(
        'demoCredential/saveDemoCredential', ({jwtToken, companyWalletUrl, id}: {jwtToken: string | undefined, companyWalletUrl: string | undefined, id: string | undefined}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };
            const body = {
                id: id,
                companyWalletUrl: (companyWalletUrl === undefined) ? '' : companyWalletUrl
            };

            return axios.post('/manage/demoCredential', body, config)
                .then(response => {
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);
