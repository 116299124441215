import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { demoCredentialSelector, getDemoCredential } from '../../state';
import { useAuth } from 'react-oidc-context';

export const SelectWallet: FC = () => {
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const [searchParams] = useSearchParams();
    const [walletUrl, setWalletUrl] = useState<string>('');
    const id = searchParams.get('id');
    let navigate = useNavigate();
    const demoCredential = useSelector(demoCredentialSelector);

    useEffect(() => {
        if (id !== null) {
            dispatch(getDemoCredential({jwtToken: auth.user?.access_token!, id: id}));
        }
    }, [auth.user?.access_token, id]);

    useEffect(() => {
        if ((demoCredential.singleItem?.companyWalletUrl !== undefined) && (demoCredential.singleItem?.companyWalletUrl.length > 0)) {
            selectWallet(demoCredential.singleItem?.companyWalletUrl);
        }
        setWalletUrl((demoCredential.singleItem?.companyWalletUrl === undefined) ? '' : demoCredential.singleItem?.companyWalletUrl);
    }, [demoCredential.singleItem?.companyWalletUrl]);

    function selectWallet(walletlUrl: string) {
        navigate('/issue?id=' + id + '&callback=' + walletlUrl);
    }

    return (

        <div className="mt-8">
            <Card variant="outlined">
                <CardHeader title='Select your organizational wallet'/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} sx={{alignItems: 'center'}}>
                                <Grid item xs={2}>
                                    Other Web Wallet
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField  variant='outlined' placeholder="Specify the Wallet URL" fullWidth margin="dense" value={walletUrl} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setWalletUrl(event.target.value);
                                    }}></TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant='outlined' onClick={() => selectWallet(walletUrl)}>Select</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};
