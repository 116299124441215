import React, { FC, useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, LinearProgress, Typography } from '@mui/material';
import { DemoCredential, demoCredentialSelector, getDemoCredentials, useAppDispatch } from '../../../state';
import { useLocation, useNavigate } from 'react-router-dom';
import { issueCredential } from '../../../state/slices/credential';
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const IssueVcForTemplate: FC = () => {
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const callbackUrl = searchParams.get('callback');
    const templateId = searchParams.get('id');
    const demoCredentials = useSelector(demoCredentialSelector);
    const navigate = useNavigate();
    const [walletUrl, setWalletUrl] = useState<string>('');


    useEffect(() => {
        dispatch(getDemoCredentials({jwtToken: auth.user?.access_token!}));
    }, [auth.user?.access_token]);

// A little bit simplified version
    const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
        arr.reduce((groups, item) => {
            (groups[key(item)] ||= []).push(item);
            return groups;
        }, {} as Record<K, T[]>);


    function getKvkNumber(credential: any) {
        if (credential['kvknummer'] !== undefined) {
            return credential['kvknummer'];
        }
        if (credential['kvkNummer'] !== undefined) {
            return credential['kvkNummer'];
        }
        if (credential['legal_person_identifier'] !== undefined) {
            return credential['legal_person_identifier']?.split('.')[1];
        }
        return undefined;
    }

    const credentials = useMemo(() =>
            groupBy(demoCredentials.list, item => getKvkNumber(JSON.parse(item.credentialJson)))
        , [demoCredentials.list]);

    if (credentials === undefined) {
        return (<></>);
    }

    function getCompanyName(credentials: any[]) {
        return credentials
            .map((credential) => JSON.parse(credential.credentialJson))
            .filter((credential) => credential['legal_person_name'] !== undefined)
            .map((credential) => credential['legal_person_name'])[0];
    }

    function getRole(credentials: any[]) {
        return 'UBO';
    }

    function getLocation(credentials: any[]) {
        return credentials
            .map((credential) => JSON.parse(credential.credentialJson))
            .filter((credential) => credential['locatie'] !== undefined)
            .map((credential) => credential['locatie'])[0];
    }

    function selectOrganization(key: string) {
        let credential = demoCredentials.list.filter(credential => credential.templateId === templateId && getKvkNumber(JSON.parse(credential.credentialJson)) === key)[0];
        issue(credential.id);
    }

    function issue(id: string) {
        navigate('/issue?id=' + id + '&callback=' + encodeURI(callbackUrl!));
    }

    function showKvkNumber(key: string, credentials: any[]) {

        return (
            <div key={key} className="grid w-full"
                 style={{boxShadow: 'unset', paddingTop: '12px', paddingBottom: '12px', paddingLeft: '0px', paddingRight: '0px', cursor: 'pointer'}}
                 onClick={() => selectOrganization(key)}>
                <div className="col-12 md:col-4 font-bold">{getCompanyName(credentials)}</div>
                <div className="col-12 md:col-2 font-light">{key}</div>
                <div className="col-12 md:col-3 font-light">{getRole(credentials)}</div>
                <div className="col-12 md:col-3 font-light">{getLocation(credentials)}</div>
            </div>
        )
    }

    if (callbackUrl && templateId) {
        return (
            <div className="mt-4">
                <Typography sx={{fontSize: '2rem', fontFamily: 'M PLUS Rounded 1c', fontWeight: 'bold', color: '#00526e'}}>Mijn KVK</Typography>
                <Typography sx={{fontSize: '1.188rem', fontFamily: 'M PLUS Rounded 1c', fontWeight: 'light', color: '#343434'}}>Hieronder zie je de organisaties waar je een functie vervult en de gegevens van de organisaties. Selecteer een van de organisaties waarvoor je de informatie wilt ontvangen.</Typography>
                <Typography sx={{fontSize: '1.5rem', fontFamily: 'M PLUS Rounded 1c', fontWeight: 'bold', color: '#00526e', marginTop: '30px', marginBottom: '20px'}}>Organisaties</Typography>
                <div className="w-full mb-4" style={{paddingRight: '1em'}}>
                    <div className="grid w-full" style={{paddingRight: '0.5rem'}}>
                        <div className="col-12 md:col-4 font-bold">Naam</div>
                        <div className="col-12 md:col-2 font-bold">KVK-nummer</div>
                        <div className="col-12 md:col-3 font-bold">Functie</div>
                        <div className="col-12 md:col-3 font-bold">Locatie</div>
                    </div>
                    {/*<div className="" style={{width: '1em'}}>s</div>*/}
                </div>
                {Object.keys(credentials)
                    .filter((key) => key !== undefined && key !== 'undefined') // Add filter to exclude undefined keys
                    .map((key) => showKvkNumber(key, credentials[key]))
                }
            </div>
        )
    } else {
        return (<div>Invalid parameters.</div>);
    }


};

