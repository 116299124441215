import React, { FC, PropsWithChildren } from "react";
import { AuthProvider } from 'react-oidc-context';


interface AuthenticationProviderProps {
}

export const AuthenticationProvider: FC<AuthenticationProviderProps & PropsWithChildren> = (props) => {
    const oidcConfig = {
        authority: process.env.REACT_APP_KEYCLOAK_URL + "/realms/" + process.env.REACT_APP_KEYCLOAK_REALM!,
        client_id: process.env.REACT_APP_KEYCLOAK_CLIENT!,
        redirect_uri: window.location.origin + window.location.pathname + window.location.search + window.location.hash + (window.location.search.length > 0 ? '&searchEnd' : '')
    };

    const onSigninCallback = (user: any | void): void => {
        let endIndex = window.location.search.indexOf('&searchEnd');
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname + window.location.search.substring(0, endIndex > 0 ? endIndex:  0)
        )
    }

    return (
        <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
            {props.children}
        </AuthProvider>
    )
};
