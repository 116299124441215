import React from 'react';
import './App.css';
import '@fontsource/m-plus-rounded-1c';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Provider } from 'react-redux';
import { store } from './state/store';
import { AuthenticationProvider } from './components/organisms/AuthenticationProvider';
import AuthenticationStateHandler from './components/organisms/AuthenticationStateHandler';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { EditCompany, IssueVc, MainLayout } from './components';
import { createTheme, CssBaseline } from '@mui/material';
import { ShowCompany } from './components/pages/ShowCompany';
import { configureAxiosDefaults } from './AxiosConfig';
import { SelectWallet } from './components/pages/SelectWallet';
import { ScanQRCode } from './components/pages/ScanQRCode';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.scss';
import { Login } from './components/pages/Login';
import { Loading } from './components/pages/Loading';
import { IssueVcForTemplate } from './components/pages/issuevc/issueVcForTemplate';

function App() {

    const theme = createTheme({
        palette: {
            primary: {
                light: '#757ce8',
                main: '#aa418c',
                dark: '#002884',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff7961',
                main: '#f44336',
                dark: '#ba000d',
                contrastText: '#000',
            },
        },
    });

    configureAxiosDefaults(store);
    return (
        <Provider store={store}>
            <CssBaseline />
            <PrimeReactProvider>
            <BrowserRouter>
                <AuthenticationProvider>
                    <AuthenticationStateHandler
                        loading={<Loading/>}
                        unAuthenticated={
                            <Routes>
                                <Route path="/" element={<Login/>}>
                                    <Route path="/" element={<ShowCompany/>}/>
                                    <Route path="/issue" element={<IssueVc/>}/>
                                    <Route path="/issue/template" element={<IssueVcForTemplate/>}/>
                                </Route>
                            </Routes>
                        }
                        authenticated={
                            <Routes>
                                <Route path="/" element={<MainLayout/>}>
                                    <Route path="/" element={<ShowCompany/>}/>
                                    <Route path="/issue/wallet" element={<SelectWallet/>}/>
                                    <Route path="/issue/personal" element={<ScanQRCode/>}/>
                                    <Route path="/issue/template" element={<IssueVcForTemplate/>}/>
                                    <Route path="/issue" element={<IssueVc/>}/>
                                    <Route path="/company/edit" element={<EditCompany/>}/>
                                </Route>
                            </Routes>
                        }
                    />
                </AuthenticationProvider>
            </BrowserRouter>
            </PrimeReactProvider>
        </Provider>

    );
}

export default App;
